import request from '@/utils/request'


// 查询公会(入驻)列表
export function listGuild(query) {
  return request({
    url: '/biz/guild/list',
    method: 'get',
    params: query
  })
}

// 查询公会(入驻)分页
export function pageGuild(query) {
  return request({
    url: '/biz/guild/page',
    method: 'get',
    params: query
  })
}

// 查询公会(入驻)详细
export function getGuild(data) {
  return request({
    url: '/biz/guild/detail',
    method: 'get',
    params: data
  })
}

// 获取当前用户公会详情
export function myGuildDetail(data) {
  return request({
    url: '/biz/guild/myGuildDetail',
    method: 'get',
    params: data
  })
}

// 新增公会(入驻)
export function addGuild(data) {
  return request({
    url: '/biz/guild/add',
    method: 'post',
    data: data
  })
}
// 修改公会(入驻)
export function auditGuild(data) {
  return request({
    url: '/biz/guild/audit',
    method: 'post',
    data: data
  })
}
// 修改公会(入驻)
export function updateGuild(data) {
  return request({
    url: '/biz/guild/edit',
    method: 'post',
    data: data
  })
}
export function editGuildDetail(data) {
  return request({
    url: '/biz/guild/editGuildDetail',
    method: 'post',
    data: data
  })
}

// 删除公会(入驻)
export function delGuild(data) {
  return request({
    url: '/biz/guild/delete',
    method: 'post',
    data: data
  })
}

export function addGuildAccount(data) {
  return request({
    url: '/biz/guild/createGuildAccount',
    method: 'post',
    data: data
  })
}
export function getGuildUserPage(query) {
  return request({
    url: '/biz/guildUser/getGuildUserPage',
    method: 'get',
    params: query
  })
}

